var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('heading',{staticClass:"mb-9",attrs:{"text":((_vm.action === 'edit' ? 'Edit' : 'New') + " Exercise"),"buttons":_vm.headingBtns,"role":"section"}}),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.validators.exercise,"disabled":_vm.loading,"label":"Name","required":"","solo":"","dense":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1),_c('form-row',{attrs:{"loading":_vm.loading,"clearOnClose":_vm.action !== 'edit',"label":"Sets & Reps","fields":[
    { element: 'text-field', key: 'sets', label: 'Sets', target: _vm.form.sets },
    { element: 'text-field', key: 'reps', label: 'Reps', target: _vm.form.reps } ]},on:{"update":function($event){_vm.form[arguments[0]] = arguments[1]}}}),_c('form-row',{attrs:{"loading":_vm.loading,"clearOnClose":_vm.action !== 'edit',"label":"Type & Amount","fields":[
    { element: 'select', key: 'unitType', label: 'Type', target: _vm.form.unitType, items: _vm.units, itemText: 'category', itemValue: 'category', change: _vm.changeUnit },
    { element: 'text-field', key: 'amount', label: 'Amount', target: _vm.form.amount },
    { element: 'select', key: 'unit', label: _vm.$data._.startCase(_vm.form.unitType), target: _vm.form.unit, items: _vm.unitTypeItems, itemText: 'name', itemValue: 'symbol' } ]},on:{"update":function($event){_vm.form[arguments[0]] = arguments[1]}}}),_c('form-row',{attrs:{"loading":_vm.loading,"clearOnClose":_vm.action !== 'edit',"label":"Tutorial Link","fields":[
    { element: 'text-field', key: 'link', label: 'Tutorial Link', target: _vm.form.link } ]},on:{"update":function($event){_vm.form[arguments[0]] = arguments[1]}}}),_c('form-row',{attrs:{"loading":_vm.loading,"clearOnClose":_vm.action !== 'edit',"label":"Notes","fields":[
    { element: 'text-area', key: 'notes', label: 'Description / Notes', target: _vm.form.notes } ]},on:{"update":function($event){_vm.form[arguments[0]] = arguments[1]}}}),_c('v-btn',{staticClass:"mt-3 v-step-2",attrs:{"loading":_vm.loading,"disabled":!_vm.valid || _vm.loading,"type":"button","color":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.action === 'edit' ? 'Update' : 'Create'))]),_c('v-btn',{staticClass:"mt-3 ml-2",attrs:{"disabled":_vm.loading,"type":"button","color":"grey lighten-1"},on:{"click":function($event){_vm.$router.from ? _vm.$router.back() : _vm.$router.push('/exercises')}}},[_vm._v("Cancel")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }